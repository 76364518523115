import Section2img from "../../../media/ifc-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Infrastructure from Code Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-28">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Extensive Expertise: </span>Our team of experts brings a wealth of experience in implementing  Infrastructure from Code solutions, using industry-leading tools and  best practices to deliver high-quality results.</li>
                        <li><span className="text-lg text-color2">Customized Approach: </span>We understand that each organization has unique needs, and we tailor our  solutions to meet your specific business requirements, ensuring a  perfect fit for your infrastructure strategy.</li>
                        <li><span className="text-lg text-color2">Commitment to Excellence: </span>We are dedicated to continuous improvement, staying at the forefront of  technological advancements to provide you with innovative and efficient  Infrastructure from Code solutions.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;