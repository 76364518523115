import Section3img from "../../../media/devops-section3.png";
import { Link } from 'react-router-dom';

function Section3() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">
            
            <div className="flex lg:flex-row flex-col justify-center items-start lg:gap-0 gap-8">
                <div className="flex-1 flex flex-col justify-center items-center gap-5 text-center lg:px-20 px-5">
                    <h3 className="text-2xl text-color3">Sharing Your Information</h3>
                    <p>We do not sell, trade, or otherwise transfer your personal information  to outside parties, except when we have your consent or are required by  law to do so.</p>
                </div>
                <div className="flex-1 flex flex-col justify-center items-center gap-5 text-center lg:px-20 px-5">
                    <h3 className="text-2xl text-color3">Security of Your Information</h3>
                    <p>We use administrative, technical, and physical security measures to help  protect your personal information. However, no electronic transmission  over the Internet or information storage technology can be guaranteed to  be 100% secure.</p>
                </div>
            </div>

        </div>
    )
}

export default Section3;