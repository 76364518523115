import Section2img from "../../../media/cloud-migration-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Turf Dev is the Ideal Partner for Cloud Migration Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team of cloud migration specialists has extensive experience in  migrating complex workloads to the cloud securely and efficiently.</li>
                        <li><span className="text-lg text-color2">Customized Approach: </span>We offer tailored migration solutions that align with your business  objectives and technical requirements, ensuring a perfect fit for your  organization.</li>
                        <li><span className="text-lg text-color2">Proven Success: </span>We have a proven track record of successful cloud migrations, helping numerous clients achieve their cloud transformation goals.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;