import Section1img from "../../../media/cloudops-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we specialize in elevating cloud operations to drive  efficiency, reliability, and innovation in your cloud infrastructure.  Our CloudOps solutions enhance cloud management by maximizing resource  utilization, automating monitoring and maintenance, and accelerating  operational excellence, enabling you to unlock the full potential of  your cloud environment.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1 text-sm">
                        <li><span className="text-base text-color2">Optimized Resource Utilization: </span> Leverage CloudOps practices to optimize cloud resources for cost  efficiency and peak performance, ensuring that you get the most value  out of your cloud investments.</li>
                        <li><span className="text-base text-color2">Automated Monitoring and Maintenance: </span>Ensure reliability and uptime with automated monitoring and proactive  maintenance, detecting and resolving issues before they impact your  operations.</li>
                        <li><span className="text-base text-color2">Operational Excellence: </span>Drive agility and innovation through seamless cloud operations  management, enabling rapid iteration and deployment of new services and  features.</li>
                        <li><span className="text-base text-color2">Scalability and Flexibility: </span>Scale your cloud infrastructure seamlessly to meet evolving business  demands, with CloudOps practices enabling dynamic resource allocation  and management.</li>
                        <li><span className="text-base text-color2">Security and Compliance: </span>Enhance security and compliance posture with CloudOps best practices,  ensuring that your cloud environment meets industry standards and  regulatory requirements.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;