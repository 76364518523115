import Section2img from "../../../media/devops-section2-2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Select Turf Dev for DevOps Excellence?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-28">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team of DevOps specialists brings extensive experience and expertise in implementing DevOps best practices tailored to your unique requirements.</li>
                        <li><span className="text-lg text-color2">Custom Solutions: </span>We offer customized DevOps solutions designed to align with your business objectives and accelerate your software delivery lifecycle.</li>
                        <li><span className="text-lg text-color2">Proven Results: </span>With a track record of successful DevOps implementations, we have helped numerous clients achieve faster time-to-market and improved software quality.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;