import Section2img from "../../../media/girl-on-blocks.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Enterprise Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">
                    <div className="lg:w-[45%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[55%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Proven Track Record: </span>Benefit from our experience in delivering successful enterprise solutions to leading organizations across various industries.</li>
                        <li><span className="text-lg text-color2">End-to-End Services: </span>Enjoy comprehensive services that cover everything from initial consultation and planning to implementation and support.</li>
                        <li><span className="text-lg text-color2">Dedicated Team: </span>Work with a dedicated team of experts who understand the unique challenges and opportunities of enterprise environments.</li>
                        <li><span className="text-lg text-color2">Innovative Technologies: </span>Stay ahead of the curve with our cutting-edge technologies and innovative approaches to problem-solving.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;