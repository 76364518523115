import React, { useState, useEffect, Suspense, lazy } from "react";
import Loading from '../../components/Loading';
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";

const Header = lazy(() => import("../../components/Header"));
const Hero = lazy(() => import("./components/Hero"));
const Section1 = lazy(() => import("./components/Section1"));
const Section2 = lazy(() => import("./components/Section2"));
const Section3 = lazy(() => import("./components/Section3"));
const Section4 = lazy(() => import("./components/Section4"));
const Section5 = lazy(() => import("./components/Section5"));
const Section6 = lazy(() => import("./components/Section6"));
const Section7 = lazy(() => import("./components/Section7"));
const Section8 = lazy(() => import("./components/Section8"));
const Section9 = lazy(() => import("./components/Section9"));
const Footer = lazy(() => import("../../components/Footer"));

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Simulate a delay for demonstration purposes
    const timer = setTimeout(handleLoad, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-white text-color1 max-w-[100vw]">
      {isLoading && <Loading />}
      <Suspense fallback={<Loading />}>
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          <Header />
          <Hero />
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <div className="mt-[30%]"></div>
          <Section6 />
          <Section7 />
          <Section8 />
          <Section9 />
          <div className="lg:-mt-[43%] -mt-[100%]"></div>
          <Footer />
        </div>
      </Suspense>
    </div>
  )
}

export default Home;