import Section1img from "../../../media/cicd-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, our CI/CD solutions are designed to streamline and automate  the software development lifecycle, enabling rapid and reliable  delivery of high-quality software. By integrating continuous integration  and continuous deployment practices, we help you achieve faster  time-to-market and improve the overall efficiency of your development processes.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1 text-sm">
                        <li><span className="text-base text-color2">Continuous Integration: </span>Automate the integration of code changes from multiple contributors, ensuring that new code is regularly tested and merged.</li>
                        <li><span className="text-base text-color2">Continuous Deployment: </span>Streamline the deployment process with automated workflows that deliver  updates and new features to production environments quickly and safely.</li>
                        <li><span className="text-base text-color2">Automated Testing: </span>Implement automated testing frameworks to ensure that code changes meet quality standards and do not introduce new issues.</li>
                        <li><span className="text-base text-color2">Rollback Mechanisms: </span>Enable automated rollback mechanisms to revert to previous versions in case of deployment failures, ensuring minimal downtime.</li>
                        <li><span className="text-base text-color2">Monitoring and Feedback: </span>Integrate monitoring tools to provide continuous feedback on the performance and stability of deployed applications.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;