import Section2img from "../../../media/devops-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for IaC Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-28">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team of IaC specialists possesses extensive experience in designing  and implementing scalable and reliable infrastructure solutions using  industry-leading tools and practices.</li>
                        <li><span className="text-lg text-color2">Custom Solutions: </span>We tailor our IaC solutions to meet your specific business requirements,  ensuring optimal performance, security, and cost-effectiveness.</li>
                        <li><span className="text-lg text-color2">Continuous Improvement: </span>We are committed to continuous improvement and optimization of your  infrastructure, leveraging the latest advancements in IaC technologies  to drive efficiency and innovation.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;