import heroImage1 from "../../../media/cloudmigration-hero.png";

function Hero() {
    return (
        <div className="h-full px-5 md:px-10">
                        
            {/* <Header userData={user} /> */}

            <div className="py-5 lg:px-20 mtp-10 lg:mt-20">
                <div className="flex lg:flex-col flex-col-reverse justify-center items-center">
                    <div className="w-full flex justify-center items-center flex-col gap-6 text-center">
                        
                        <h1 className="text-[36px] md:text-[48px] lg:text-left text-center text-color2">Seamlessly Transition to the <span className="text-color3">Cloud</span></h1>
                        <p className="text-xl">Efficient and Secure Cloud Migration Solutions</p>
                        <div className="w-[60%] lg:w-[40%] border-t border-black border-opacity-50"></div>

                    </div>
                    <div className="mt-4 w-[80%] lg:w-[35%] flex justify-center items-center">
                        <img className="rotate-[23deg]" src={heroImage1} alt="" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Hero;