function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Refund and Cancellation Policy</h2>

                <div className="flex justify-center items-center flex-col gap-16">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <h4 className="lg:px-24 text-3xl text-center text-color2">Refunds</h4>
                        <p className="lg:px-24 text-2xl text-center text-color3">We strive to deliver top-notch cloud hosting management services. Our refund policy is designed to be transparent and fair:</p>
                    </div>

                    <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                        <ul className="list-disc disc-color1">
                            <li><span className="text-lg text-color2">No Refunds for Monthly Usage: </span>Refunds are not provided for monthly services already consumed. Cloud hosting operates on a pay-as-you-use model, and fees are based on the resources utilized during the billing cycle.</li>
                            <li><span className="text-lg text-color2">Refunds for Overcharges or Billing Errors: </span>If you believe there has been an overcharge or billing error, please contact our support team within 7 days of the charge. Upon verification, approved refunds will be processed within 5-7 business days to the original payment method.</li>
                            <li><span className="text-lg text-color2">Prepayment Refunds: </span>We may require personal details such as your name, email address, phone number, and any other information necessary to provide our services and process transactions.</li>
                        </ul>
                    </div>
                </div>

                <div className="flex justify-center items-center flex-col gap-16">
                    <div className="flex flex-col justify-center items-center gap-4">
                        <h4 className="lg:px-24 text-3xl text-center text-color2">Cancellation Policy</h4>
                        <p className="lg:px-24 text-2xl text-center text-color3">We aim to provide flexibility while ensuring uninterrupted service. Please review the terms below for cancellation:</p>
                    </div>

                    <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                        <ul className="list-disc disc-color1">
                            <li><span className="text-lg text-color2">Monthly Service Usage: </span>All services are billed monthly, with automatic deductions from the card on file based on usage. If payment is not received at the start of the billing cycle, services will be suspended until the payment is successfully made.</li>
                            <li><span className="text-lg text-color2">Card Requirement for Server Creation: </span>A valid payment card must be added during the initial cloud server creation. Without a valid card on server creation and service activation are not permitted.</li>
                            <li><span className="text-lg text-color2">Automatic Payments: </span>After the initial month of service, payments will be automatically deducted from the registered payment card based on the monthly usage. If the automatic payment fails, clients will have 7 days to resolve the payment issue. If payment is not received within this 7-day period, services will be suspended until the payment is resolved.</li>
                            <li><span className="text-lg text-color2">Cancellation of Services: </span>Clients may cancel services at any time via their account dashboard. Upon cancellation, all hosted services, including data and backups, will be terminated immediately or at the end of the billing cycle (as per client’s choice).</li>
                            <li><span className="text-lg text-color2">Account Termination for Non-Payment: </span>If payment is not made for 30 days post-due date, the account will be considered inactive, and all data and services associated with it will be permanently deleted.</li>
                        </ul>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Section2;