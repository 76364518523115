import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";

import companyLogo from '../media/logo.png';

function Header() {

    const [isMenuOpen, setMenuOpen] = useState(false);
    // const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isServicesOpen, setServicesOpen] = useState(false);
    const [isSolutionsOpen, setSolutionsOpen] = useState(false);

    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        setServicesOpen(false);
        setSolutionsOpen(false);
    };

    const toggleServices = () => {
        setServicesOpen(!isServicesOpen);
        setSolutionsOpen(false);
    }

    const toggleSolutions = () => {
        setSolutionsOpen(!isSolutionsOpen);
        setServicesOpen(false);
    }
    
    // const openDropdown = () => {
    //     setDropdownOpen(true);
    // };

    // const closeDropdown = () => {
    //     setDropdownOpen(false);
    // };

    // Function to collapse submenu when clicking outside the menu area
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setServicesOpen(false);
                setSolutionsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);
    
    return (
        <>
            <nav className="sticky h-20 w-full top-0 px-5 md:px-28 z-50 py-6 lg:flex justify-between shadow bg-glass-white">
                <div className="flex flex-1 justify-between items-center flex-row-reverse w-full">
                    
                    <div className="flex flex-1 items-center justify-end lg:justify-start">
                        <Link className="lg:w-12 w-10 leading-4 cursor-pointer" to="/">
                            <img src={companyLogo} alt="TurfDev Pvt. Ltd." />
                        </Link>
                    </div>
                    <div className="lg:hidden flex justify-start flex-1" onClick={toggleMenu}>
                        <i className={isMenuOpen ? "cursor-pointer text-2xl fa-solid fa-x" : "cursor-pointer text-2xl fa-solid fa-bars"}></i>
                    </div>
                </div>
                
                {/* MAIN MENU STARTS */}
                <ul ref={menuRef} className={isMenuOpen ? "absolute left-0 w-full h-[100vh] bg-white leading-10 md:pt-20 pt-10 pb-10 font-bold text-[20px] uppercase px-4 md:px-10 overflow-auto" : "hidden lg:flex items-center gap-10 text-sm"}>

                    <div className="lg:hidden mt-4 pb-4 border-t-[1px] border-color2 border-opacity-50"></div>
                    <li className="" onClick={toggleServices}>
                        <div className="flex justify-start cursor-pointer hover:text-color2 transition duration-150 ease-in-out">
                            <p className={isServicesOpen ? "hidden lg:flex gap-1" : "hidden lg:flex w-full gap-1"}>
                                Services <i className={isServicesOpen ? "text-xs mt-1 fa-solid fa-angle-up" : "text-xs mt-1 fa-solid fa-angle-down"}></i>
                            </p>
                            <p className={isServicesOpen ? "lg:hidden w-full flex justify-between lg:justify-start lg:gap-1" : "lg:hidden w-full flex justify-between lg:gap-1 lg:justify-start"}>
                                Services <i className={isServicesOpen ? "text-lg mt-2 fa-solid fa-angle-left" : "text-lg mt-2 fa-solid fa-angle-right"}></i>
                            </p>
                        </div>
                        <div className={isServicesOpen ? "absolute left-0 lg:w-auto w-full lg:left-20 lg:right-20 lg:top-20 top-0 shadow-custom2 bg-white lg:shadow-2xl text-black font-normal text-sm leading-8 px-8 pb-20 lg:pb-10 pt-10 rounded-xl" : "hidden"}>
                            <div className="lg:hidden flex gap-1 justify-start items-center mb-5">
                                <i className="fa-solid fa-angle-left"></i>
                                <p onClick={toggleServices} className="cursor-pointer hover:text-color2">Menu</p>
                            </div>
                            <div className="flex flex-col lg:flex-row justify-center items-start gap-10">
                                <ul className="text-left flex items-start flex-col gap-10">
                                    <li className="">
                                        <Link to="/cloud-management" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-cloud text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Cloud Management</p>
                                                <p className="text-xs -mt-1">Streamline operations, optimize resources, <br/>and enhance efficiency with Cloud Management.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/cloud-migration" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-people-carry-box text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Cloud Migration</p>
                                                <p className="text-xs -mt-1">Seamlessly transition to the cloud with <br/>expert migration solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/server-administration" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-server text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Server Administration</p>
                                                <p className="text-xs -mt-1">Efficiently manage and optimize server <br/>resources with expert administration.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/containerization" className="flex items-center justify-center gap-4">
                                            <i className="fa-brands fa-docker text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Containerization</p>
                                                <p className="text-xs -mt-1">Simplify deployment and scaling with <br/>efficient containerization solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="text-left flex items-start flex-col gap-10">
                                    <li className="">
                                        <Link to="/kubernetes" className="flex items-center justify-center gap-4">
                                            <i className="lni lni-kubernetes text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Kubernetes</p>
                                                <p className="text-xs -mt-1">Streamline orchestration and management with <br/>powerful Kubernetes solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/continuous-integration-and-continuous-deployment" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-timeline text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">CI/CD Pipeline</p>
                                                <p className="text-xs -mt-1">Automate workflows and accelerate deployment <br/>with robust CI/CD pipelines.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/infrastructure-as-code" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-code text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Infrastructure as Code (IaC)</p>
                                                <p className="text-xs -mt-1">Automate infrastructure provisioning with seamless<br/>Infrastructure as Code solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/infrastructure-from-code" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-sitemap text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Infrastructure from Code</p>
                                                <p className="text-xs -mt-1">Empower development with dynamic <br/>Infrastructure from Code solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                <ul className="text-left flex items-start flex-col gap-10">
                                    <li className="">
                                        <Link to="/cloud-security" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-shield-halved text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Cloud Security</p>
                                                <p className="text-xs -mt-1">Protect your assets with comprehensive <br/>cloud security solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </li>

                    <div className="lg:hidden mt-4 pb-4 border-t-[1px] border-color2 border-opacity-50"></div>
                    <li className="" onClick={toggleSolutions}>
                        <div className="flex justify-start cursor-pointer hover:text-color2 transition duration-150 ease-in-out">
                            <p className={isSolutionsOpen ? "hidden lg:flex gap-1" : "hidden lg:flex w-full gap-1"}>
                                Solutions <i className={isSolutionsOpen ? "text-xs mt-1 fa-solid fa-angle-up" : "text-xs mt-1 fa-solid fa-angle-down"}></i>
                            </p>
                            <p className={isSolutionsOpen ? "lg:hidden flex w-full justify-between lg:justify-start lg:gap-1" : "lg:hidden w-full flex justify-between lg:gap-1 lg:justify-start"}>
                                Solutions <i className={isSolutionsOpen ? "text-lg mt-2 fa-solid fa-angle-left" : "text-lg mt-2 fa-solid fa-angle-right"}></i>
                            </p>
                        </div>
                        <div className={isSolutionsOpen ? "h-[100vh] md:h-auto absolute lg:w-auto w-full lg:left-auto left-0 lg:top-20 top-0 shadow-custom2 bg-white lg:shadow-2xl text-black font-normal text-sm leading-8 px-8 py-10 rounded-xl" : "hidden"}>
                        {/* <div className={isSolutionsOpen ? "absolute top-20 sm:w-full lg:w-auto shadow-custom2 bg-white text-black font-normal text-sm leading-8 px-8 py-10 rounded" : "hidden"}> */}
                            <div className="lg:hidden flex gap-1 justify-start items-center mb-5">
                                <i className="fa-solid fa-angle-left"></i>
                                <p onClick={toggleSolutions} className="cursor-pointer hover:text-color2">Menu</p>
                            </div>
                            <div className="flex flex-col md:flex-row justify-center items-start gap-10 md:flex-wrap">
                                <ul className="text-left flex items-start flex-col gap-10">
                                    <li className="">
                                        <Link to="/devops" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-infinity text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">DevOps</p>
                                                <p className="text-xs -mt-1">Bridge development and operations seamlessly <br/>with agile DevOps practices.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/cloudops" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-cloud text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">CloudOps</p>
                                                <p className="text-xs -mt-1">Seamlessly transition to the cloud with <br/>expert migration solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/devsecops" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-shield-virus text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">DevSecOps</p>
                                                <p className="text-xs -mt-1">Embed security at every stage of development<br/> for robust, automated protection.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/zero-downtime-deployment" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-clock text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Zero Downtime</p>
                                                <p className="text-xs -mt-1">Ensure uninterrupted operations with resilient <br/>Zero Downtime strategies.</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li className="">
                                        <Link to="/enterprise-solutions" className="flex items-center justify-center gap-4">
                                            <i className="fa-solid fa-industry text-xl text-grad-1"></i>
                                            <div>
                                                <p className="font-bold text-grad-1">Enterprise Solutions</p>
                                                <p className="text-xs -mt-1">Empower your organization with tailored <br/>enterprise-grade solutions.</p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </li>

                    <div className="lg:hidden mt-4 pb-4 border-t-[1px] border-color2 border-opacity-50"></div>
                    <li className="cursor-pointer hover:text-color2 transition duration-150 ease-in"><Link to="/">Docs</Link></li>
                    
                    <div className="lg:hidden mt-4 pb-4 border-t-[1px] border-color2 border-opacity-50"></div>
                    <li className="cursor-pointer hover:text-color2 transition duration-150 ease-in"><Link to="/">Company</Link></li>
                    
                    <div className="lg:hidden mt-4 pb-4 border-t-[1px] border-color2 border-opacity-50"></div>
                    <li className="lg:hidden text-xl mt-8">
                        <Link to="https://meetings.hubspot.com/aditya-pancholi" className="button1 cursor-pointer transition duration-150 ease-in-out font-light">
                            Get Started
                        </Link>
                    </li>
                </ul>
                {/* MAIN MENU ENDS */}
                
                <div className="hidden lg:flex items-center justify-center text-center flex-1 gap-4 lg:justify-end">
                    <Link to="https://meetings.hubspot.com/aditya-pancholi" className="button1 cursor-pointer transition duration-150 ease-out text-xs">
                        Book A Demo
                    </Link>
                    <Link to="https://meetings.hubspot.com/aditya-pancholi" className="button2 cursor-pointer transition duration-150 ease-out text-xs">
                        Get Started
                    </Link>
                </div>
                
            </nav>
        </>
    )

}

export default Header;