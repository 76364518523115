import Section2img from "../../../media/serveradmin-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Server Administration?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Experienced Team: </span>Our server administrators possess extensive knowledge and experience in  managing diverse server environments, ensuring high reliability and  performance.</li>
                        <li><span className="text-lg text-color2">Tailored Solutions: </span>We offer customized server administration services that align with your  specific business requirements, ensuring a perfect fit for your server  infrastructure.</li>
                        <li><span className="text-lg text-color2">Proven Track Record: </span>We have a history of successfully managing server environments for  numerous clients, ensuring optimal uptime, security, and performance.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;