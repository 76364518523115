function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <div className="flex flex-col justify-center items-center gap-4">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Your Data Protection Rights</h2>
                    <p className="lg:px-24 text-2xl text-center text-color3">Depending on your location, you may have the following rights regarding your personal data:</p>
                </div>

                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                    <ul className="px-5 lg:px-10 list-disc disc-color1">
                        <li><span className="text-lg text-color2">The right to access— </span>You have the right to request copies of your personal data.</li>
                        <li><span className="text-lg text-color2">The right to rectification— </span>You have the right to request that we correct any information you believe is inaccurate.</li>
                        <li><span className="text-lg text-color2">The right to erasure— </span>You have the right to request that we erase your personal data, under certain conditions.</li>
                        <li><span className="text-lg text-color2">The right to restrict processing— </span>You have the right to request that we  restrict the processing of your personal data, under certain  conditions.</li>
                        <li><span className="text-lg text-color2">The right to object to processing— </span>You have the right to object to our processing of your personal data, under certain conditions.</li>
                        <li><span className="text-lg text-color2">The right to data portability— </span>You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;