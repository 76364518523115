import Section1Img from "../../../media/terms-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <p className="lg:px-24 text-xl text-center">Welcome to Turf Dev. These terms and conditions outline the rules and regulations for using our Cloud Management Hosting services. By accessing or using our services, you agree to comply with these terms.</p>
                <h2 className="lg:px-24 text-center text-color2 text-4xl">Information We May Require</h2>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section1Img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Personal Details: </span>We may require personal details such as your name, email address, phone number, and any other information necessary to provide our services and process transactions.</li>
                        <li><span className="text-lg text-color2">Transaction Details: </span>Information related to payments, invoices, and order history may be collected to manage your purchases and process refunds or cancellations.</li>
                        <li><span className="text-lg text-color2">Usage Data: </span>We may gather data about your interactions with our services, such as IP address, device type, browser information, and access logs, to ensure compliance with our terms and improve your experience.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;