import Section1img from "../../../media/ifc-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we specialize in transforming how organizations manage their infrastructure through our Infrastructure from Code solutions. By leveraging advanced coding practices to define, deploy, and manage your IT infrastructure, we ensure greater agility, consistency, and efficiency, enabling your business to adapt swiftly to changing demands.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center lg:gap-28 gap-6">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img className="rounded-xl" src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Declarative Configurations: </span>Simplify infrastructure management by using declarative configurations,  ensuring that your infrastructure is always aligned with the desired  state defined in code.</li>
                        <li><span className="text-lg text-color2">Rapid Deployment: </span>Accelerate the deployment of infrastructure with code-driven processes,  allowing for quicker iterations and faster time-to-market for new  features and services.</li>
                        <li><span className="text-lg text-color2">Consistency and Reliability: </span>Achieve consistent and reliable deployments by defining infrastructure  configurations as code, reducing discrepancies and ensuring uniformity  across all environments.</li>
                        <li><span className="text-lg text-color2">Efficient Resource Management: </span>Optimize resource allocation and usage by managing infrastructure  resources through automated, code-based practices, leading to cost  savings and better performance.</li>
                        <li><span className="text-lg text-color2">Enhanced Security and Compliance: </span>Integrate security and compliance checks within your code to enforce  policies and standards automatically, minimizing risks and ensuring  adherence to regulatory requirements.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;