import Section1Img from "../../../media/containerization-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we understand that containerization is a game-changer in modern application deployment, providing a foundation for scalable and efficient IT infrastructure.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section1Img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Efficient Application Packaging: </span>Containerization bundles an application and its dependencies into a  single container, ensuring consistency across different environments  from development to production.</li>
                        <li><span className="text-lg text-color2">Isolation and Security: </span>Containers offer process and network isolation, enhancing security by  minimizing the attack surface and preventing conflicts between different  applications.</li>
                        <li><span className="text-lg text-color2">Rapid Deployment and Rollback: </span>Containers enable faster deployment times and quick rollbacks, improving the agility of your development and operations teams.</li>
                        <li><span className="text-lg text-color2">Portability Across Platforms: </span>Containers run consistently across any platform that supports container runtimes, reducing environment-related issues and ensuring application portability.</li>
                        <li><span className="text-lg text-color2">Resource Efficiency: </span>Containers are lightweight and require fewer resources compared to traditional virtual machines, leading to more efficient utilization of computing resources and reduced costs.</li>
                        <li><span className="text-lg text-color2">Improved Scalability: </span>Containers can be started or stopped quickly, allowing your infrastructure to adapt to varying workloads and ensuring applications handle peak traffic efficiently.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;