function Section6() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full">
                <div className="flex flex-col justify-center items-center gap-16">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Changes to This Policy</h2>
                    <p className="lg:px-24 text-center">We may update our Terms and Conditions with Refund and Cancellation Policy from time to time. Any changes will be communicated by posting the updated policy on this page. We encourage you to review this policy periodically to stay informed about updates that may affect your use of our services.</p>
                </div>
            </div>



        </div>
    )
}

export default Section6;