function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full">
                <div className="flex flex-col justify-center items-center gap-16">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Changes to This Privacy Policy</h2>
                    <p className="lg:px-24 text-center">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                </div>
            </div>



        </div>
    )
}

export default Section2;