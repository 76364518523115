import Section2img from "../../../media/cloudops-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">What Makes Turf Dev Your Best Choice for CloudOps Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team of CloudOps specialists brings extensive experience and  expertise in designing and implementing cloud operations solutions  tailored to your specific business needs.</li>
                        <li><span className="text-lg text-color2">Customized Approach: </span>We offer customized CloudOps solutions designed to align with your  business objectives and optimize your cloud environment for maximum  efficiency and performance.</li>
                        <li><span className="text-lg text-color2">Continuous Improvement: </span>We are committed to continuous improvement and optimization of your  cloud operations, leveraging the latest advancements in cloud  technologies and best practices to drive excellence.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;