import Section1Img from "../../../media/serveradmin-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we understand the critical role that servers play in maintaining the stability and performance of your IT infrastructure. Our Server Administration solutions are designed to ensure that your servers are managed efficiently, securely, and in alignment with your business objectives, allowing you to focus on your core operations with confidence.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section1Img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Real-Time Monitoring: </span>Implement continuous monitoring to ensure your cloud resources are performing optimally and to detect potential issues before they impact your operations.</li>
                        <li><span className="text-lg text-color2">Resource Optimization: </span>Analyze and optimize cloud resource usage to reduce costs and improve efficiency, ensuring you only pay for what you need.</li>
                        <li><span className="text-lg text-color2">Robust Security Measures: </span>Apply advanced security protocols and best practices to safeguard your cloud infrastructure against threats and vulnerabilities.</li>
                        <li><span className="text-lg text-color2">Dynamic Scaling: </span>Automatically scale your cloud resources up or down based on demand,  ensuring optimal performance during peak times and cost savings during  low-usage periods.</li>
                        <li><span className="text-lg text-color2">24/7 Support and Incident Management: </span>Provide round-the-clock support to address any issues promptly and  implement efficient incident management processes to quickly resolve any  disruptions and restore normal operations.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;