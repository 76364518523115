function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <div className="flex flex-col justify-center items-center gap-4">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Use of Your Information</h2>
                    <p className="lg:px-24 text-2xl text-center text-color3">We may use the information we collect for various purposes, including:</p>
                </div>

                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                    <ul className="list-disc">
                        <li>To provide and maintain our service.</li>
                        <li>To notify you about changes to our service.</li>
                        <li>To allow you to participate in interactive features of our service when you choose to do so.</li>
                        <li>To provide customer support.</li>
                        <li>To gather analysis or valuable information so that we can improve our service.</li>
                        <li>To monitor the usage of our service.</li>
                        <li>To detect, prevent, and address technical issues</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;