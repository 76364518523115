function Section5() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <div className="flex flex-col justify-center items-center gap-4">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Your Data Protection Rights</h2>
                    <p className="lg:px-24 text-2xl text-center text-color3">In relation to our Terms and Conditions and Refund and Cancellation Policy for cloud hosting management services, you may have the following rights regarding your personal data:</p>
                </div>

                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                    <ul className="px-5 lg:px-10 list-disc disc-color1">
                        <li><span className="text-lg text-color2">The right to access – </span>You can request a copy of the personal data we maintain in connection with your account and transactions.</li>
                        <li><span className="text-lg text-color2">The right to rectification – </span>You can request corrections to inaccuracies in the data related to your payment details or service usage.</li>
                        <li><span className="text-lg text-color2">The right to erasure – </span>You can request the deletion of your personal data, provided no outstanding payments or disputes exist, and subject to legal retention requirements.</li>
                        <li><span className="text-lg text-color2">The right to restrict processing – </span>You can request limitations on the use of your personal data for purposes other than billing or service delivery.</li>
                        <li><span className="text-lg text-color2">The right to object to processing – </span>You can object to the use of your data for purposes such as automated payment processing, under certain conditions.</li>
                        <li><span className="text-lg text-color2">The right to data portability – </span>You can request that your personal data, such as billing and usage records, be transferred to another service provider or directly to you, where feasible.</li>
                    </ul>
                </div>

            </div>
            
        </div>
    )
}

export default Section5;