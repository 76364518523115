import React from "react";
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";

import Header from "../../components/Header";

import Hero from "./components/Hero";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";

import Footer from "../../components/Footer";

function PrivacyPolicy() {

    return (
        <div className="bg-white text-color1 max-w-[100vw]">
            
            <Header />

            <Hero />
            
            <Section1 />

            <Section2 />
            
            <Section3 />

            <Section4 />

            <Section5 />

            <Section6 />
            
            <Footer />

        </div>
    )
    
}

export default PrivacyPolicy;
