import Gear from "../../../media/gears.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we specialize in zero downtime deployment strategies that ensure your applications remain available and responsive even during updates and maintenance. Our expert solutions are designed to minimize disruptions and maintain a seamless user experience, ensuring business continuity and customer satisfaction.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Gear} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Blue-Green Deployment: </span>Switch traffic between two identical environments to update applications without affecting users.</li>
                        <li><span className="text-lg text-color2">Rolling Updates: </span>Gradually deploy updates across servers to ensure smooth transitions and prevent downtime.</li>
                        <li><span className="text-lg text-color2">Canary Releases: </span>Test updates with a small subset of users before a full rollout to catch and resolve issues early.</li>
                        <li><span className="text-lg text-color2">Feature Toggles: </span>Enable or disable features in real-time without deploying new code, allowing for safe experimentation and rapid rollbacks if needed.</li>
                        <li><span className="text-lg text-color2">Automated Rollbacks: </span>Automatically revert to a previous stable state if issues are detected during deployment, ensuring minimal impact on users.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;