import Section2img from "../../../media/cloudops-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for CI/CD Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span> Our CI/CD specialists have extensive experience in building and  maintaining efficient CI/CD pipelines tailored to your specific needs.</li>
                        <li><span className="text-lg text-color2">Customization: </span>We provide customized CI/CD solutions that align with your development workflows and business goals.</li>
                        <li><span className="text-lg text-color2">Efficiency: </span>By automating repetitive tasks, we help you save time and resources,  allowing your teams to focus on innovation and feature development.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;