import Section6img from "../../../media/devops-section3.png";
import { Link } from 'react-router-dom';

function Section3() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">
            
            <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-0 gap-8 lg:px-0 px-5">
                <div className="lg:w-[50%] flex justify-center items-center">
                    <img className="w-full" src={Section6img} alt=""/>
                </div>
                <div className="lg:w-[50%] text-center lg:pr-20 flex justify-center items-center flex-col gap-8">
                    <h3 className="text-xl text-color2">Your Privacy is Our Priority</h3>
                    <p className="mt-4">If you have any questions about this Privacy Policy, please contact us.</p>
                    <Link to="https://meetings.hubspot.com/aditya-pancholi" className="button3">Contact Us</Link>
                </div>
            </div>

        </div>
    )
}

export default Section3;