import Section1img from "../../../media/cicd-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we specialize in providing seamless cloud migration  services that enable your business to transition to the cloud with  minimal disruption. Our cloud migration solutions ensure that your  applications and data are securely and efficiently moved to the cloud,  allowing you to leverage the benefits of scalability, cost-efficiency,  and enhanced performance.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Comprehensive Migration Planning: </span>Develop a detailed migration plan tailored to your specific business  needs, ensuring a smooth and structured transition to the cloud.</li>
                        <li><span className="text-lg text-color2">Secure Data Transfer: </span> Implement robust security measures to protect your data during the migration process, ensuring confidentiality and integrity.</li>
                        <li><span className="text-lg text-color2">Minimal Downtime: </span>Utilize best practices and advanced tools to minimize downtime and ensure business continuity during the migration.</li>
                        <li><span className="text-lg text-color2">Application Modernization: </span>Modernize your applications as part of the migration process, optimizing  them for cloud environments to improve performance and scalability.</li>
                        <li><span className="text-lg text-color2">Post-Migration Support: </span>Provide ongoing support and optimization services post-migration to ensure your cloud environment remains efficient and secure.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;