import Section1img from "../../../media/devsecops-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we recognize the critical importance of integrating  security into the development and operations processes. Our DevSecOps  solutions seamlessly embed security practices into every stage of the  DevOps lifecycle, ensuring that your applications are not only delivered  quickly but also securely.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center lg:gap-28 gap-6">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Security by Design: </span>Incorporate security from the initial design phase, ensuring that all applications are built with a robust security foundation.</li>
                        <li><span className="text-lg text-color2">Automated Security Testing: </span>Implement automated security testing within your CI/CD pipelines to  detect and address vulnerabilities early in the development process.</li>
                        <li><span className="text-lg text-color2">Continuous Monitoring: </span>Continuously monitor your applications and infrastructure for security  threats, ensuring proactive detection and response to potential issues.</li>
                        <li><span className="text-lg text-color2">Compliance and Governance: </span> Ensure compliance with industry standards and regulatory requirements  through integrated security practices and continuous auditing.</li>
                        <li><span className="text-lg text-color2">Threat Intelligence: </span>Leverage advanced threat intelligence to stay ahead of emerging security  threats and protect your applications from evolving risks.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;