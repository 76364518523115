import React from "react";
import { Link } from 'react-router-dom';

function Footer({ user }) {
    return (
        <>
            <footer className="px-5 md:px-28 mt-48 text-sm text-left w-full">
                <div className="">
                    <div className="w-full flex flex-col lg:flex-row gap-8">
                        <div className="flex-1">
                            <h1 className="text-xl font-light mb-4 text-color2">Turf Dev—</h1>
                            <p>Your gateway to seamless cloud solutions and digital transformation.</p>
                            <div className="w-[170px] mt-10">
                                <Link to="https://meetings.hubspot.com/aditya-pancholi" className="button1 font-light transition duration-150 ease-out">Contact
                                </Link>
                            </div>
                        </div>

                        <div className="flex-1">
                            <h1 className="text-xl font-light mb-4 text-color2">Explore Further</h1>
                            <ul className="leading-8">
                                <li><Link className="hover:text-color2 hover:pl-1 transition duration-150 ease-in-out" to="/"><i className="fa-solid fa-arrow-right"></i> Home</Link></li>
                                <li><Link className="hover:text-color2 hover:pl-1 transition duration-150 ease-in-out" to=""><i className="fa-solid fa-arrow-right"></i> Docs</Link></li>
                                <li><Link className="hover:text-color2 hover:pl-1 transition duration-150 ease-in-out" to="/terms-conditions-refunds-policy"><i className="fa-solid fa-arrow-right"></i> Terms & Conditions</Link></li>
                                <li><Link className="hover:text-color2 hover:pl-1 transition duration-150 ease-in-out" to="/privacy-policy"><i className="fa-solid fa-arrow-right"></i> Privacy Policy</Link></li>
                                <li><Link className="hover:text-color2 hover:pl-1 transition duration-150 ease-in-out" to=""><i className="fa-solid fa-arrow-right"></i> About Us</Link></li>
                            </ul>
                        </div>
                        
                        <div className="flex-1">
                            <h1 className="text-xl font-light mb-4 text-color2">Get in Touch</h1>
                            <div><i className="fa-solid fa-location-dot"></i> 110, Near Hotel Bella-Vista, Shri Vihar, Durgapura, Jaipur, Rajasthan 302018</div>
                            <div className="mt-2"><i className="fa-solid fa-phone"></i> +91 99506 23222</div>
                            <div className="mt-2"><i className="fa-solid fa-envelope"></i> info@turfdev.in</div>
                            <div className="flex items-center justify-center mr-20 py-1 text-white text-3xl md:text-2xl mt-8 md:gap-2 gap-4 bg-color2 rounded">
                                <Link to="https://facebook.com/"><i className="fa-brands fa-facebook cursor-pointer hover:scale-110 hover:text-blue-600 transition duration-150 ease-in-out"></i></Link>
                                <Link to="https://twitter.com/"><i className="fa-brands fa-x-twitter cursor-pointer hover:scale-110 hover:text-black transition duration-150 ease-in-out"></i></Link>
                                {/* <Link to="https://twitter.com/"><i className="fa-brands fa-twitter cursor-pointer hover:scale-110 hover:text-cyan-600 transition duration-150 ease-in-out"></i></Link> */}
                                <Link to="https://linkedin.com/"><i className="fa-brands fa-linkedin cursor-pointer hover:scale-110 hover:text-sky-800 transition duration-150 ease-in-out"></i></Link>
                                <Link to="https://youtube.com/"><i className="fa-brands fa-youtube cursor-pointer hover:scale-110 hover:text-red-700 transition duration-150 ease-in-out"></i></Link>
                                {/* <Link to="https://instagram.com/"><i className="fa-brands fa-instagram cursor-pointer hover:scale-110 hover:text-gray-950 transition duration-150 ease-in-out"></i></Link> */}
                            </div>
                        </div>

                        <div className="flex-1">
                            <h1 className="text-xl font-light mb-4 text-color2">Stay in the loop</h1>
                            <p>Subscribe for the latest updates and offers.</p>
                            <input className="mt-5 bg-transparent outline-none text-color2" type="text" placeholder="Email" />
                            <div className="border-t-[1px] border-color2 mt-2 md:mx-50 md:max-w-sm border-opacity-50"></div>
                            <button className="mt-4 button3 font-light transition duration-150 ease-out" type="submit">
                                Grow With US
                            </button>
                        </div>
                    </div>

                    <div className="lg:mx-40 sm:mx-20 mt-28 border-t-[1px] border-color2 border-opacity-50"></div>
                    <div className="flex justify-center text-center py-5">
                        Copyright &copy; 2024 Turfdev. All rights reserved.
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
