import Section1img from "../../../media/cloudsecurity-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-12">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we understand that securing your cloud environment is crucial to protecting your business operations and sensitive data. Our Cloud Security solutions offer a robust and comprehensive approach to safeguarding your cloud infrastructure against threats, ensuring compliance, and maintaining trust with your clients.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Proactive 24/7 Monitoring: </span>Our dedicated team continuously monitors your server performance and  health, promptly detecting and resolving issues to ensure optimal uptime  and performance round the clock.</li>
                        <li><span className="text-lg text-color2">Regular Maintenance Routines: </span>We conduct routine maintenance tasks, including software updates,  patches, and backups, to keep your servers operating smoothly and  securely, minimizing the risk of vulnerabilities and downtime.</li>
                        <li><span className="text-lg text-color2">Robust Security Implementation: </span>Our experts implement advanced security measures to fortify your servers  against cyber threats, safeguarding data integrity and confidentiality,  and ensuring compliance with industry regulations and standards.</li>
                        <li><span className="text-lg text-color2">Efficient Resource Management: </span>We optimize server resources to enhance performance, reduce latency, and  ensure efficient utilization of hardware and software, maximizing the  efficiency of your server infrastructure.</li>
                        <li><span className="text-lg text-color2">Strategic Disaster Recovery Planning: </span>Our comprehensive disaster recovery plans ensure swift restoration of  operations in the event of an outage or disaster, backed by reliable  backup solutions that store critical data in secure locations for quick  recovery.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;