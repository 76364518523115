import Stack from "../../../media/stack.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we believe in harnessing the power of DevOps to drive  innovation, agility, and efficiency in software development. Our DevOps  solutions optimize collaboration and automation to empower teams and  accelerate software delivery, ensuring high-quality code and rapid  deployment.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center lg:gap-28 gap-6">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Stack} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Streamlined Collaboration: </span>Foster collaboration between development and operations teams to break down silos and enhance communication.</li>
                        <li><span className="text-lg text-color2">Automated Workflows: </span>Implement automated CI/CD pipelines to streamline the software delivery process and minimize manual errors.</li>
                        <li><span className="text-lg text-color2">Continuous Integration and Delivery: </span>Embrace CI/CD practices to continuously integrate code changes and deliver software updates swiftly and reliably.</li>
                        <li><span className="text-lg text-color2">Agile Development Dynamics: </span>Enable agile development methodologies by automating testing, deployment, and monitoring processes.</li>
                        <li><span className="text-lg text-color2">Innovation and Agility: </span>Drive innovation and adaptability by enabling rapid iteration and deployment of new features and functionalities.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;