import Section1Img from "../../../media/privacypolicy-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <p className="lg:px-24 text-xl text-center">Welcome to Turf Dev. We value your privacy and are committed to  protecting your personal information. This Privacy Policy explains how  we collect, use, disclose, and safeguard your information when you visit  our website turfdev.in.</p>
                <h2 className="lg:px-24 text-center text-color2 text-4xl">Information We Collect</h2>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section1Img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Personal Information: </span>We may collect personal information that you provide to us directly,  such as your name, email address, phone number, and any other details  you choose to provide.</li>
                        <li><span className="text-lg text-color2">Usage Data: </span>We automatically collect information about your interaction with our  website, such as IP address, browser type, pages visited, time spent on  pages, and referring URL.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;