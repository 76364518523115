import React from "react";
// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";

import Header from "../../components/Header";

import Hero from "./components/Hero";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";

import Footer from "../../components/Footer";

function Containerization() {

    return (
        <div className="bg-white text-color1 max-w-[100vw]">
            
            <Header />

            <Hero />
            
            <Section1 />

            <Section2 />
            
            <Section3 />
            
            <Footer />

        </div>
    )
    
}

export default Containerization;
