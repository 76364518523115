import heroImage1 from "../../../media/terms-hero.png";

function Hero() {
    return (
        <div className="h-full px-5 md:px-10">
                        
            {/* <Header userData={user} /> */}

            <div className="py-5 lg:px-20 mt-10">
                <div className="flex lg:flex-col flex-col-reverse justify-center items-center">
                    <div className="w-full flex justify-center items-center flex-col gap-6 text-center">
                        
                        <h1 className="text-[36px] md:text-[44px] text-center text-color2">Terms and Conditions with Refund and Cancellation Policy</h1>
                        <p className="text-xl">Experience Secure, Transparent, and Hassle-Free Cloud Solutions</p>
                        <div className="mt-6 w-[60%] border-t border-black border-opacity-50"></div>

                    </div>
                    <div className="w-[80%] lg:w-[50%] flex justify-center items-center">
                        <img className="" src={heroImage1} alt="" />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Hero;