function Section4() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">
            
            <div className="flex lg:flex-row flex-col justify-center items-start lg:gap-0 gap-8">
                <div className="flex-1 flex flex-col justify-center items-center gap-5 text-center lg:px-20 px-5">
                    <h3 className="text-2xl text-color2">Sharing Your Information</h3>
                    <p>We do not sell, trade, or transfer your personal information to external parties, except with your consent, to comply with legal obligations, or to trusted service providers assisting us in delivering cloud management services.</p>
                </div>
                <div className="flex-1 flex flex-col justify-center items-center gap-5 text-center lg:px-20 px-5">
                    <h3 className="text-2xl text-color2">Security of Your Information</h3>
                    <p>We implement administrative, technical, and physical safeguards to protect your personal information, but no electronic transmission or storage system can guarantee 100% security.</p>
                </div>
            </div>

        </div>
    )
}

export default Section4;