import Section2img from "../../../media/question-marks.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Partner with Turf Dev for Zero Downtime Deployment?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise in Deployment Strategies: </span> Leverage our extensive experience to implement the best practices in zero downtime deployment.</li>
                        <li><span className="text-lg text-color2">Advanced Tooling: </span>Utilize industry-leading tools and technologies to automate and streamline deployment processes.</li>
                        <li><span className="text-lg text-color2">Custom Solutions: </span>Tailor deployment strategies to fit the unique needs and requirements of your organization.</li>
                        <li><span className="text-lg text-color2">24/7 Support: </span>Benefit from round-the-clock support to address any deployment issues promptly and efficiently.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;