import Section1img from "../../../media/iac-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we understand the importance of streamlining infrastructure  operations and ensuring scalability in today's dynamic environment. Our  Infrastructure as Code (IaC) solutions empower organizations to  automate infrastructure provisioning and management, driving efficiency,  reliability, and consistency across deployments.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center lg:gap-28 gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img className="rounded-xl" src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Code-Based Configurations: </span> Ensure consistency and reliability across deployments by defining infrastructure configurations as code.</li>
                        <li><span className="text-lg text-color2">Automated Provisioning: </span>Streamline infrastructure provisioning processes with automated workflows, reducing manual efforts and minimizing errors.</li>
                        <li><span className="text-lg text-color2">Scalability: </span>Scale your infrastructure seamlessly to meet growing demands, with IaC enabling rapid provisioning of resources as needed.</li>
                        <li><span className="text-lg text-color2">Resource Utilization: </span>Maximize resource utilization and optimize costs by efficiently managing  infrastructure resources through code-based configurations.</li>
                        <li><span className="text-lg text-color2">Reduced Manual Errors: </span>Minimize the risk of human error in infrastructure setup and configuration changes by leveraging IaC practices.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;