import Section2img from "../../../media/cloudmanagement-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Cloud Management?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-6">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img className='lg:w-[80%]' src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team of cloud management experts brings years of experience and a  deep understanding of various cloud platforms, ensuring your cloud  environment is in capable hands.</li>
                        <li><span className="text-lg text-color2">Customized Solutions: </span>We tailor our cloud management services to align with your specific  business needs and objectives, ensuring you get the most out of your  cloud investment.</li>
                        <li><span className="text-lg text-color2">Proven Track Record: </span>We have a history of successful cloud management engagements, helping  numerous clients optimize their cloud environments for maximum  performance and cost-efficiency.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;