import Section2img from "../../../media/cloudsecurity-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Cloud Security Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-6">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expert Security Team: </span>Our team of cloud security experts has extensive experience in designing  and implementing robust security measures tailored to your unique cloud  environment.</li>
                        <li><span className="text-lg text-color2">Custom Solutions: </span>We offer customized security solutions that align with your specific business needs, ensuring optimal protection and compliance.</li>
                        <li><span className="text-lg text-color2">Proven Success: </span>We have a track record of successfully securing cloud environments for  numerous clients, helping them achieve their security and compliance  goals.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;