import companyLogo from '../media/logo.png';

const Loading = () => {
    return (
        <div className='h-screen bg-white flex flex-col justify-center items-center'>
            <img className="absolute top-20 w-[15%] lg:w-[6%] rounded" src={companyLogo} alt="" />
            <div className="flex justify-center items-center gap-2">
                <div className="spinner"></div>
                <p className='font-bold'>Loading...</p>
            </div>
        </div>
    )
}

export default Loading;