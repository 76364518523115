import Section1img from "../../../media/Kubernetes.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">At Turf Dev, we specialize in empowering organizations with Kubernetes  (K8s) to orchestrate containerized environments effectively. Our  Kubernetes solutions streamline the management of containerized  applications, enhance resource efficiency, and boost operational  agility, enabling you to achieve seamless scalability, reliability, and  innovation in your deployments.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center lg:gap-28 gap-6">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img className="rounded-xl" src={Section1img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Efficient Container Orchestration: </span>Utilize Kubernetes to streamline the management of containerized  applications, ensuring consistency and reliability across deployments.</li>
                        <li><span className="text-lg text-color2">Scalable Deployments with Helm: </span>Leverage Helm charts to simplify the deployment and management of  complex Kubernetes applications, accelerating the development lifecycle.</li>
                        <li><span className="text-lg text-color2">Resource Efficiency: </span>Optimize resource allocation and utilization for cost-effective and  high-performance deployments, maximizing the ROI of your infrastructure  investments.</li>
                        <li><span className="text-lg text-color2">Automated Scaling and Load Balancing: </span>Ensure seamless scalability and reliability with automated orchestration  of container resources, enabling your applications to handle varying  workloads efficiently.</li>
                        <li><span className="text-lg text-color2">Operational Agility: </span>Accelerate innovation by simplifying deployment and management processes  with Kubernetes, enabling rapid iteration and delivery of new features  and functionalities.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;