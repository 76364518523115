function Section3() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <div className="flex flex-col justify-center items-center gap-4">
                    <h2 className="lg:px-24 text-4xl text-center text-color2">Use of Your Information</h2>
                    <p className="lg:px-24 text-2xl text-center text-color3">We collect and use your information to provide seamless and efficient cloud hosting management services. This includes, but is not limited to:</p>
                </div>

                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">                    
                    <ul className="px-5 lg:px-10 list-disc disc-color1">
                        <li><span className="text-lg text-color2">Service Delivery and Maintenance: </span>To set up, configure, and maintain your cloud hosting environment. To ensure uninterrupted access to your cloud services.</li>
                        <li><span className="text-lg text-color2">Notifications and Updates: </span>To inform you about service changes, updates, or scheduled maintenance.</li>
                        <li><span className="text-lg text-color2">Interactive Features: </span>To enable participation in features such as server scaling, performance analytics, or custom configurations.</li>
                        <li><span className="text-lg text-color2">Customer Support: </span>To assist with technical queries, troubleshoot issues, and resolve customer concerns promptly.</li>
                        <li><span className="text-lg text-color2">Service Improvement: </span>To analyze usage data and feedback to enhance the efficiency, security, and features of our hosting solutions.</li>
                        <li><span className="text-lg text-color2">Usage Monitoring: </span>To monitor resource utilization, ensure compliance with usage limits, and optimize server performance.</li>
                        <li><span className="text-lg text-color2">Issue Detection and Prevention: </span>To identify, address, and prevent technical issues or unauthorized activities on your account or server.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section3;