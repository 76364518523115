import Gear from "../../../media/enterprise-section1.png";

function Section1() {
    return (
        <div className="w-full px-5 md:px-10 mt-20">

            <div className="w-full flex justify-center items-center flex-col gap-24">
                <p className="lg:px-24 text-xl text-center">Turf Dev offers comprehensive enterprise solutions designed to meet the complex needs of large organizations. Our services provide scalability, security, and efficiency to drive business growth and innovation. Whether you need to streamline operations, enhance security, or scale your infrastructure, we have the expertise to deliver robust solutions that align with your business objectives.</p>
                <div className="lg:px-20 px-5 flex lg:flex-row flex-col justify-center items-center gap-6 lg:gap-20">
                    <div className="lg:w-[50%] flex justify-center items-center">
                        <img src={Gear} alt=""/>
                    </div>
                    <ul className="lg:w-[50%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Scalable Infrastructure: </span>Design and implement scalable infrastructure solutions to support business growth and peak demand.</li>
                        <li><span className="text-lg text-color2">Advanced Security Measures: </span>Implement enterprise-grade security protocols to protect your data and systems from threats.</li>
                        <li><span className="text-lg text-color2">Comprehensive Cloud Solutions: </span>Leverage cloud technologies to optimize costs, enhance performance, and improve agility.</li>
                        <li><span className="text-lg text-color2">Custom Software Development: </span>Develop bespoke software solutions tailored to your specific business requirements and goals.</li>
                        <li><span className="text-lg text-color2">Data Analytics and Insights: </span>Utilize advanced analytics to gain actionable insights and drive data-informed decision-making.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section1;