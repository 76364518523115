import Section2img from "../../../media/containerization-section2.png";

function Section2() {
    return (
        <div className="w-full px-5 md:px-10 mt-32">

            <div className="w-full flex justify-center items-center flex-col gap-16">
                <h2 className="lg:px-24 text-4xl text-center text-color2">Why Choose Turf Dev for Containerization Solutions?</h2>
                <div className="lg:px-32 px-5 flex lg:flex-row-reverse flex-col justify-center items-center gap-10">
                    <div className="lg:w-[40%] flex justify-center items-center">
                        <img src={Section2img} alt=""/>
                    </div>
                    <ul className="lg:w-[60%] list-disc disc-color1">
                        <li><span className="text-lg text-color2">Expertise: </span>Our team has deep expertise in designing and implementing container solutions tailored to your specific business needs.</li>
                        <li><span className="text-lg text-color2">Advanced Tooling: </span>We use industry-leading tools and practices to streamline the containerization process and enhance operational efficiency.</li>
                        <li><span className="text-lg text-color2">Continuous Support: </span>We provide ongoing support and optimization services to keep your containerized environment efficient, secure, and up-to-date.</li>
                    </ul>
                </div>

            </div>



        </div>
    )
}

export default Section2;